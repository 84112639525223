import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PNLogo from '../../assets/images/PNLogo.png';
import { handleScroll } from '../../utils/ScrollUtil';
import { useJobListings } from "../../utils/JobListingContext"; // JSON mit den Stellenanzeigen

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { hasJobs } = useJobListings();

  const [isAnimated, setIsAnimated] = useState(true);

  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimated(false);
    }, 5500); 
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const cleanup = handleScroll(() => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
    return cleanup;
  });

  return (
    <header className={`fixed top-0 w-full z-50 font-semibold transition-colors duration-300 ${isScrolled || isMenuOpen ? 'bg-white' : 'bg-inherit'}`}>
      <div className="flex items-center justify-between py-4 standardPadding">
        <Link to='/'>
          <div
            className={`bg-left bg-cover h-8 transition-all duration-300 ${isScrolled ? 'w-[154px]' : 'w-10'}`}
            style={{ backgroundImage: `url(${PNLogo})` }}
            alt="PNLogo"
          />
        </Link>
        <nav className='mr-4'>
          <ul className="hidden md:flex space-x-6">
            <li><Link to="/support" className={`hover:text-blue-900 ${isScrolled ? 'text-black' : 'text-white'}`}>Support</Link></li>
            <li><Link to="/pricing" className={`hover:text-blue-900 ${isScrolled ? 'text-black' : 'text-white'}`}>Preise</Link></li>
            {hasJobs && (
              <li className={`${isAnimated ?  'motion-safe:animate-bounce' : 'animate-none'}`}>
                <Link to="/jobs" className={`hover:text-blue-900 transition-colors duration-1000 ${isScrolled ? 'text-black' : 'text-white'}`}>Karriere</Link></li>
            )}
            <li><Link to="/contact" className={`hover:text-blue-900 ${isScrolled ? 'text-black' : 'text-white'}`}>Kontakt</Link></li>
          </ul>
          <button
            className="md:hidden"
            onClick={toggleMenu}>
            {!isMenuOpen && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" width="24" height="24" className="h-6 w-6">
                <path d="M1 8 h 22 M1 15 h22 M1 1h22" fill="none" stroke="#000080" strokeWidth="2" />
              </svg>
            )}
            {isMenuOpen && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="h-6 w-6">
                <path d="M2 2 L22 22 M2 22 L22 2" fill="none" stroke="#000080" strokeWidth="2" />
              </svg>
            )}
          </button>
        </nav>
      </div>
      {isMenuOpen && (
        <div className="md:hidden px-8 pb-4 text-lg">
          <ul className="space-y-2">
            <li><Link to="/contact" className="block hover:text-blue-900">Kontakt</Link></li>
            <li><Link to="/support" className="block hover:text-blue-900">Support</Link></li>
            {hasJobs && (
              <li><Link to="/jobs" className="block hover:text-blue-900">Karriere</Link></li>
            )}
            <li><Link to="/pricing" className="block hover:text-blue-900">Preise</Link></li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
